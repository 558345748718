<template>
  <div class="history_movies_warp">
    <PullRefresh
      :loading="loading"
      :refreshing="refreshing"
      :finished="finished"
      @onLoad="onLoad"
      :is-higeht-max="true"
      :disabled="true"
      :isNoData="isNoData"
    >
      <router-link tag="div" :to="`/tags/video?id=${item.id}`" class="topicItem" v-for="(item, index) in list" :key="index">
        <div class="selectBox" @click.stop="selectItem(item)" :class="{ activeSelectBox: isEdit }">
          <svg-icon class="select" iconClass="batchSelected" v-if="item.select" />
          <svg-icon class="select" iconClass="batchUnSelect" v-else />
        </div>
        <div class="main" :class="{ activeMain: isEdit }">
          <div class="content">
            <div class="">
              <ImgDecypt class="avatar" :src="item.coverImg" />
            </div>
            <div class="topicInfo">
              <div class="topicTitle">#{{ item.name }}</div>
              <div class="topicNum">话题总播放次数 {{ item.playCount | watchCount }}</div>
            </div>
          </div>
        </div>
      </router-link>
    </PullRefresh>
  </div>
</template>
<script>
import { queryHistoryTopic } from '@/utils';
import PullRefresh from '@/components/PullRefresh';
import { deleteDB, openDB } from '@/utils/indexedDB';
import ImgDecypt from '@/components/ImgDecypt';
import { Toast } from 'vant';

export default {
  name: 'HistoryTopic',
  props: ['isEdit'],
  components: {
    PullRefresh,
    ImgDecypt,
  },
  data() {
    return {
      pageNumber: 1,
      pageSize: 20,
      list: [],
      loading: true,
      refreshing: false,
      finished: false,
      isNoData: false,
      error: false,
    };
  },
  async created() {
    this.getList();
  },
  methods: {
    //获取列表
    async getList(type) {
      let list = (await queryHistoryTopic(this.pageNumber, this.pageSize)) || [];

      if (type === 'refresh') {
        this.list = list;
      } else {
        this.list = this.list.concat(list);
      }
      this.list = this.uniqueFun(this.list, 'id');
      if (this.pageNumber == 1 && this.list.length == 0) {
        this.isNoData = true;
      }
      if (list.length < this.pageSize) {
        this.finished = true;
        return;
      }
      this.loading = false;
    },
    // 选择批量删除的数据
    selectItem(work) {
      this.list.map((item) => (item.id == work.id ? this.$set(item, 'select', !item.select) : ''));
    },
    // 选择全部
    selectAll(isSelectAll) {
      this.list.map((item) => this.$set(item, 'select', isSelectAll));
    },
    // 批量删除
    batchDel() {
      const delArr = this.list.map((item) => (item.select ? item.id : undefined)).filter((item) => item);
      if (delArr.length == 0) return Toast('至少选择一项进行删除');
      this.delItem(delArr);
    },
    async delItem(delArr) {
      let db = await openDB('rms_db');
      this.list = this.list.filter((item) => {
        let flag = delArr.find((vId) => item.id == vId);
        if (flag) {
          deleteDB(db, 'topicList', flag);
        } else {
          return item;
        }
      });
      if (this.list.length == 0) return this.$emit('close');
    },
    uniqueFun(arr, type) {
      const res = new Map();
      return arr.filter((a) => !res.has(a[type]) && res.set(a[type], 1));
    },
    onLoad() {
      this.loading = true;
      this.pageNumber++;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.history_movies_warp {
  height: 100%;
  .topicItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    .selectBox {
      padding: 0 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translateX(-42px);
      transition: transform 0.3s;
      .select {
        height: 22px;
        width: 22px;
        border-radius: 50%;
      }
    }
    .main {
      background-color: #fff;
      width: 100%;
      padding: 8px 0px;
      transform: translateX(-42px);
      transition: transform 0.3s;
      .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 350px;
        margin: 0 auto;
        padding: 0 12px;
        .avatar {
          width: 58px;
          height: 58px;
          border-radius: 6px;
          overflow: hidden;
          position: relative;
          z-index: 1;
        }

        .topicInfo {
          flex: 2;
          height: 58px;
          margin: 0 7px;
          padding-top: 5px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .topicTitle {
            font-size: 16px;
          }

          .topicNum {
            font-size: 12px;
            color: rgb(102, 102, 102);
            margin-bottom: 6px;
          }
        }

        .collected {
          width: 70px;
          height: 27px;
          border-radius: 27px;
          line-height: 27px;
          text-align: center;
          border: 1px solid rgb(201, 201, 201);
          .text {
            font-size: 14px;
            color: rgb(201, 201, 201);
          }
        }
      }
    }
    .activeSelectBox {
      transform: translateX(0px);
      transition: transform 0.3s;
    }
    .activeMain {
      transform: translateX(0px);
      transition: transform 0.3s;
    }
  }
}
</style>
